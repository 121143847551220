import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { TranslateAssetTypePipe } from '@shared/pipes/translate-enum-pipes/translate-asset-type.pipe';

import { LoadingSpinnerComponent } from '../loading-spinner';
import { SiteOverviewTableViewModel } from './site-overview-table.view-model';

@Component({
  selector: 'app-site-overview-table',
  standalone: true,
  imports: [MatTableModule, TranslateModule, LoadingSpinnerComponent, TranslateAssetTypePipe, DecimalPipe],
  templateUrl: './site-overview-table.component.html',
  styleUrl: './site-overview-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteOverviewTableComponent {
  public vm = input.required<SiteOverviewTableViewModel>();

  public readonly displayedColumns = ['asset', 'assetType', 'activePowerKw'];
}
