@if (vm(); as vm) {
  <h5 class="flex items-center gap-4">
    {{ 'dashboard.site-graph-history-graph.title' | translate }}
    @if (vm.isLoading) {
      <app-loading-spinner size="small"></app-loading-spinner>
    }
  </h5>

  <div class="relative h-[400px]">
    <canvas #chartContainer></canvas>
  </div>
}
