import { OnDestroy, Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { AsyncPipeBase } from '../async-pipe-base';

@Pipe({
  name: 'translateEnumPipeBase',
  pure: false,
})
export abstract class TranslateEnumPipeBase<T extends string> extends AsyncPipeBase<T> implements PipeTransform, OnDestroy {
  readonly #translateService = inject(TranslateService);
  protected readonly defaultValue = '';

  protected abstract valueTranslateKeyMap: Record<T, string>;

  public transform$(value: T): Observable<string> {
    return this.valueTranslateKeyMap[value] ? this.#translateService.stream(this.valueTranslateKeyMap[value]) : of(this.defaultValue);
  }
}
