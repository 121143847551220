/* eslint-disable sonarjs/no-identical-functions */
import { AssetType } from '@core/api/dashboard.enums';
import {
  Asset,
  AssetMeteringDataUnit,
  AssetMeteringResponse,
  SiteMeteringDataUnit,
  SiteMeteringResponse,
  SiteOverviewResponse,
  SiteStructureResponse,
  SitesResponse,
} from '@core/api/dashboard.models';

import { AssetTypeStoreModelEnum } from '@domain/dashboard/dashboard.enums';
import {
  AssetMeteringDataUnitStoreModel,
  AssetMeteringStoreModel,
  SiteAssetStoreModel,
  SiteMeteringDataUnitStoreModel,
  SiteMeteringStoreModel,
  SiteOverviewStoreModel,
  SiteStoreModel,
  SiteStructureStoreModel,
} from '@domain/dashboard/dashboard.models';

export const mapSitesResponseApiModelToSiteStoreModels = (response: SitesResponse): SiteStoreModel[] => {
  return response.sites.map((site) => ({
    id: site.id,
    name: site.name,
  }));
};

export const mapSiteStructureResponseApiModelToStoreModel = (response: SiteStructureResponse): SiteStructureStoreModel => {
  return {
    siteId: response.siteId,
    siteName: response.siteName,
    assets: response.assets.map((asset) => mapAssetApiModelToStoreModel(asset)),
  };
};

export const mapAssetApiModelToStoreModel = (asset: Asset): SiteAssetStoreModel => {
  return {
    id: asset.id,
    name: asset.name,
  };
};

export const mapSiteOverviewResponseApiModelToStoreModels = (response: SiteOverviewResponse): SiteOverviewStoreModel[] => {
  return response.latestMeteredData.map(
    (data): SiteOverviewStoreModel => ({
      asset: data.asset,
      assetType: mapAssetTypeApiModelEnumToStoreModelEnum(data.assetType),
      activePowerKw: data.activePower / 1000,
    })
  );
};

export const mapAssetTypeApiModelEnumToStoreModelEnum = (assetType: AssetType): AssetTypeStoreModelEnum => {
  return {
    [AssetType.Grid]: AssetTypeStoreModelEnum.Grid,
    [AssetType.Bess]: AssetTypeStoreModelEnum.Bess,
    [AssetType.DistributionComponent]: AssetTypeStoreModelEnum.DistributionComponent,
    [AssetType.Evse]: AssetTypeStoreModelEnum.Evse,
    [AssetType.ChargingStation]: AssetTypeStoreModelEnum.ChargingStation,
  }[assetType];
};

export const mapSiteMeteringResponseApiModelToStoreModel = (response: SiteMeteringResponse): SiteMeteringStoreModel => {
  const meteredData = {} as Record<AssetTypeStoreModelEnum, SiteMeteringDataUnitStoreModel[]>;

  Object.entries(response.meteredData).forEach(([key, value]) => {
    meteredData[mapAssetTypeApiModelEnumToStoreModelEnum(key as AssetType)] = value.map((data) =>
      mapSiteMeteringDataUnitApiModelToStoreModel(data)
    );
  });

  return {
    meteredData,
  };
};

export const mapSiteMeteringDataUnitApiModelToStoreModel = (data: SiteMeteringDataUnit): SiteMeteringDataUnitStoreModel => {
  return {
    timestamp: data.timestamp,
    activePowerKw: data.activePower / 1000,
  };
};

export const mapAssetMeteringResponseApiModelToStoreModel = (response: AssetMeteringResponse, assetId: string): AssetMeteringStoreModel => {
  return {
    meteredData: response.meteredData[assetId].map((data) => mapAssetMeteringDataUnitApiModelToStoreModel(data)),
  };
};

export const mapAssetMeteringDataUnitApiModelToStoreModel = (data: AssetMeteringDataUnit): AssetMeteringDataUnitStoreModel => {
  return {
    timestamp: data.timestamp,
    activePowerKw: data.activePower / 1000,
  };
};
