import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CONFIG_TOKEN } from '@core/tokens';

import {
  AssetMeteringResponse,
  SiteMeteringResponse,
  SiteOverviewResponse,
  SiteStructureResponse,
  SitesResponse,
} from './dashboard.models';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  readonly #ROOT_URL = inject(CONFIG_TOKEN).backendRootUrl;

  readonly #httpClient = inject(HttpClient);

  public getSiteList$(): Observable<SitesResponse> {
    return this.#httpClient.get<SitesResponse>(`${this.#ROOT_URL}/api/v1/sites`);
  }

  public getSiteStructure$(id: string): Observable<SiteStructureResponse> {
    return this.#httpClient.get<SiteStructureResponse>(`${this.#ROOT_URL}/api/v1/sites/${id}/structure`);
  }

  public getSiteOverview$(id: string): Observable<SiteOverviewResponse> {
    return this.#httpClient.get<SiteOverviewResponse>(`${this.#ROOT_URL}/api/v1/sites/${id}/overview`);
  }

  public getSiteMetering$(id: string): Observable<SiteMeteringResponse> {
    return this.#httpClient.get<SiteMeteringResponse>(`${this.#ROOT_URL}/api/v1/sites/${id}/site-metering`);
  }

  public getAssetMetering$(id: string): Observable<AssetMeteringResponse> {
    return this.#httpClient.get<AssetMeteringResponse>(`${this.#ROOT_URL}/api/v1/assets/${id}/asset-metering`);
  }
}
