import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';
import { DateTime } from 'luxon';
import { Observable, map, zip } from 'rxjs';

import { LoadingSpinnerComponent } from '../loading-spinner';
import { AssetGraphHistoryGraphViewModel } from './asset-graph-history-graph.view-model';

@Component({
  selector: 'app-asset-graph-history-graph',
  standalone: true,
  imports: [LoadingSpinnerComponent, TranslateModule],
  templateUrl: './asset-graph-history-graph.component.html',
  styleUrl: './asset-graph-history-graph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetGraphHistoryGraphComponent implements OnChanges, AfterViewInit, OnDestroy {
  readonly #translateService = inject(TranslateService);
  readonly #destroyRef = inject(DestroyRef);

  public vm = input.required<AssetGraphHistoryGraphViewModel>();
  public chartContainer = viewChild<ElementRef<HTMLCanvasElement>>('chartContainer');

  private chart?: Chart<'line', { x: string; y: number }[], unknown>;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['vm'] && this.chart) {
      this.updateDataOnChart();
    }
  }

  public ngAfterViewInit(): void {
    this.initChart();
  }

  public ngOnDestroy(): void {
    this.chart?.destroy();
  }

  private initChart(): void {
    const chartContainer = this.chartContainer();
    if (!chartContainer) {
      return;
    }

    const ctx = chartContainer.nativeElement.getContext('2d')!;

    this.getDatasets$()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((datasets) => {
        this.chart = new Chart(ctx, {
          type: 'line',
          data: {
            datasets,
          },
        });
      });
  }

  private updateDataOnChart(): void {
    this.getDatasets$()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((datasets) => {
        this.chart?.data.datasets.forEach((dataset, index) => {
          dataset.data = datasets[index].data;
        });

        this.chart?.update();
      });
  }

  private getDatasets$(): Observable<
    {
      label: string;
      data: {
        x: string;
        y: number;
      }[];
    }[]
  > {
    return zip([this.#translateService.stream('dashboard.asset-graph-history-graph.active-power')]).pipe(
      map(([activePowerLabel]) => {
        const vm = this.vm();

        return [
          {
            label: activePowerLabel,
            data: vm.graphData.map((graphData) => {
              return {
                x: DateTime.fromISO(graphData.timestamp).toFormat('hh:mm:ss a').toUpperCase(),
                y: graphData.activePowerKw,
              };
            }),
          },
        ];
      })
    );
  }
}
