@if (siteSelectorVm(); as siteSelectorVm) {
  @if (siteSelectorVm.sitesIsLoading) {
    <app-loading-spinner [text]="'dashboard.loading-sites' | translate"></app-loading-spinner>
  }
  @if (siteSelectorVm.sites) {
    <form class="flex flex-col items-start gap-4">
      <h4>{{ 'dashboard.select-site' | translate }}</h4>

      <mat-form-field>
        <mat-label>{{ 'dashboard.sites-label' | translate }}</mat-label>
        <select [formControl]="siteControl" matNativeControl>
          @for (site of siteSelectorVm.sites; track site.id) {
            <option value="{{ site.id }}">{{ site.name }}</option>
          }
        </select>
      </mat-form-field>
    </form>
  }
}

@if (siteDetailsVm(); as siteDetailsVm) {
  @if (siteDetailsVm.siteDataIsLoading && !siteDetailsVm.siteOverview) {
    <app-loading-spinner [text]="'dashboard.loading-site-details' | translate"></app-loading-spinner>
  }

  @if (siteDetailsVm.siteOverview) {
    @if (siteOverviewTableVm(); as siteOverviewTableVm) {
      <app-site-overview-table [vm]="siteOverviewTableVm"></app-site-overview-table>
    }

    @if (siteGraphHistoryGraphVm(); as siteGraphHistoryGraphVm) {
      <app-site-graph-history-graph [vm]="siteGraphHistoryGraphVm"></app-site-graph-history-graph>
    }

    @if (assetSelectorVm(); as assetSelectorVm) {
      @if (assetSelectorVm.assets) {
        <form class="flex flex-col items-start gap-4">
          <h4>{{ 'dashboard.select-asset' | translate }}</h4>

          <mat-form-field>
            <mat-label>{{ 'dashboard.assets-label' | translate }}</mat-label>
            <select [formControl]="assetControl" matNativeControl>
              @for (asset of assetSelectorVm.assets; track asset.id) {
                <option value="{{ asset.id }}">{{ asset.name }}</option>
              }
            </select>
          </mat-form-field>
        </form>
      }
    }

    @if (assetDetailsVm(); as assetDetailsVm) {
      @if (assetDetailsVm.assetDataIsLoading && !assetDetailsVm.assetHistory) {
        <app-loading-spinner [text]="'dashboard.loading-asset-details' | translate"></app-loading-spinner>
      }

      @if (assetDetailsVm.assetHistory) {
        @if (assetGraphHistoryGraphVm(); as assetGraphHistoryGraphVm) {
          <app-asset-graph-history-graph [vm]="assetGraphHistoryGraphVm"></app-asset-graph-history-graph>
        }
      }
    }
  }
}
