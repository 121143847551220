import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Pipe({
  name: 'asyncPipeBase',
  pure: false,
})
export abstract class AsyncPipeBase<FirstInputType, RestInputTypes extends unknown[] = [], ResultType = string>
  implements PipeTransform, OnDestroy
{
  readonly #cdr = inject(ChangeDetectorRef);

  private subscription?: Subscription;
  private lastResult$?: Observable<ResultType>;
  private lastInputParameters?: [FirstInputType, ...RestInputTypes];
  private lastEmittedTranslation!: ResultType;
  protected abstract readonly defaultValue: ResultType;

  public transform(firstInput: FirstInputType, ...inputParameters: [...RestInputTypes]): ResultType {
    const allInputParameters: [FirstInputType, ...RestInputTypes] = [firstInput, ...inputParameters];
    if (
      !this.lastInputParameters ||
      this.lastInputParameters?.length !== allInputParameters?.length ||
      this.lastInputParameters.some((lastInputParameter, i) => allInputParameters[i] !== lastInputParameter)
    ) {
      this.lastInputParameters = allInputParameters;

      const newResult$ = this.transform$(...allInputParameters);

      if (newResult$ !== this.lastResult$) {
        this.lastResult$ = newResult$;

        this.subscribeToNewObservable();
      }
    }

    return this.lastEmittedTranslation;
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private subscribeToNewObservable(): void {
    this.lastEmittedTranslation = this.defaultValue;
    this.subscription?.unsubscribe();

    this.subscription = this.lastResult$?.subscribe((newTranslation) => {
      this.lastEmittedTranslation = newTranslation;
      this.#cdr.markForCheck();
    });
  }

  protected abstract transform$(firstInput: FirstInputType, ...inputParameters: [...RestInputTypes]): Observable<ResultType>;
}
