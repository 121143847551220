import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

import { AssetGraphHistoryGraphComponent } from './components/asset-graph-history-graph';
import { LoadingSpinnerComponent } from './components/loading-spinner';
import { SiteGraphHistoryGraphComponent } from './components/site-graph-history-graph';
import { SiteOverviewTableComponent } from './components/site-overview-table';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    TranslateModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerComponent,
    SiteOverviewTableComponent,
    SiteGraphHistoryGraphComponent,
    AssetGraphHistoryGraphComponent,
  ],
  providers: [DashboardService],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  readonly #dashboardService = inject(DashboardService);
  readonly #destroyRef = inject(DestroyRef);

  public readonly siteSelectorVm = this.#dashboardService.siteSelectorVm;
  public readonly siteDetailsVm = this.#dashboardService.siteDetailsVm;
  public readonly siteOverviewTableVm = this.#dashboardService.siteOverviewTableVm;
  public readonly siteGraphHistoryGraphVm = this.#dashboardService.siteGraphHistoryGraphVm;
  public readonly assetSelectorVm = this.#dashboardService.assetSelectorVm;
  public readonly assetDetailsVm = this.#dashboardService.assetDetailsVm;
  public readonly assetGraphHistoryGraphVm = this.#dashboardService.assetGraphHistoryGraphVm;
  public readonly siteControl = new FormControl<string | null>(this.siteSelectorVm().selectedSiteId);
  public readonly assetControl = new FormControl<string | null>(this.assetSelectorVm().selectedAssetId);

  public ngOnInit(): void {
    this.siteControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((siteId) => {
      if (siteId) {
        this.assetControl.setValue(null, {
          emitEvent: false,
        });
        this.#dashboardService.selectSite(siteId);
      }
    });

    this.assetControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((siteId) => {
      if (siteId) {
        this.#dashboardService.selectAsset(siteId);
      }
    });
  }
}
