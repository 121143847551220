import { APP_INITIALIZER, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { DEFAULT_LANG } from '@shared/utils/locales';

const preloadTranslationsFactory = (translate: TranslateService) => {
  return (): Observable<unknown> => {
    translate.setDefaultLang(DEFAULT_LANG);
    return translate.use(DEFAULT_LANG);
  };
};

export const translationsPreloadInitializer: Provider = {
  provide: APP_INITIALIZER,
  useFactory: preloadTranslationsFactory,
  deps: [TranslateService],
  multi: true,
};
