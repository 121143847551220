import { Pipe, PipeTransform } from '@angular/core';

import { AssetTypeStoreModelEnum } from '@domain/dashboard/dashboard.enums';

import { TranslateEnumPipeBase } from '../translate-enum-pipe-base';

const KEY_PREFIX = 'enums.asset-type';
export const AssetTypeKeyMapping = {
  [AssetTypeStoreModelEnum.Grid]: `${KEY_PREFIX}.grid`,
  [AssetTypeStoreModelEnum.Bess]: `${KEY_PREFIX}.bess`,
  [AssetTypeStoreModelEnum.DistributionComponent]: `${KEY_PREFIX}.distribution-component`,
  [AssetTypeStoreModelEnum.Evse]: `${KEY_PREFIX}.evse`,
  [AssetTypeStoreModelEnum.ChargingStation]: `${KEY_PREFIX}.charging-station`,
};

@Pipe({
  name: 'translateAssetType',
  pure: false,
  standalone: true,
})
export class TranslateAssetTypePipe extends TranslateEnumPipeBase<AssetTypeStoreModelEnum> implements PipeTransform {
  protected readonly valueTranslateKeyMap = AssetTypeKeyMapping;
}
