import { Routes } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardEffects } from './pages/dashboard/store/dashboard.effects';
import { dashboardFeatureKey, dashboardReducer } from './pages/dashboard/store/dashboard.reducer';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    providers: [
      provideState({
        name: dashboardFeatureKey,
        reducer: dashboardReducer,
      }),
      provideEffects(DashboardEffects),
    ],
  },
];
