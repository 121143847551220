import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { MonoTypeOperatorFunction, Observable, Subscription, take } from 'rxjs';

export const cancelOnAction =
  <T>(actions$: Actions, actions: ActionCreator | ActionCreator[], cancelAction?: ActionCreator): MonoTypeOperatorFunction<T> =>
  (source$) => {
    return new Observable<T>((subscriber) => {
      const actionsAsArray: ActionCreator[] = Array.isArray(actions) ? actions : [actions];
      let sub: Subscription;
      let actionsSub: Subscription;

      const subToSource = () => {
        sub = source$.subscribe({
          next: (v) => subscriber.next(v),
          complete: () => subscriber.complete(),
          error: (err) => subscriber.error(err),
        });
      };

      const subToActions = () => {
        actionsSub = actions$.pipe(ofType(...actionsAsArray), take(1)).subscribe(() => {
          if (cancelAction) {
            subscriber.next(cancelAction() as T);
          }
          sub?.unsubscribe();
          subToSource();
          subToActions();
        });
      };

      subToSource();
      subToActions();

      return () => {
        sub?.unsubscribe();
        actionsSub?.unsubscribe();
      };
    });
  };
