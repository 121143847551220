/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AssetGraphHistoryGraphViewModel } from '../components/asset-graph-history-graph';
import { SiteGraphHistoryGraphViewModel } from '../components/site-graph-history-graph';
import { SiteOverviewTableViewModel } from '../components/site-overview-table';
import {
  DashboardAssetDetailsVm,
  DashboardAssetSelectorVm,
  DashboardSiteDetailsVm,
  DashboardSiteSelectorVm,
} from '../dashboard.view-model';
import { DashboardState, dashboardFeatureKey } from './dashboard.reducer';

export const selectDashboardFeature = createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const selectSiteList = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteList);
export const selectSiteListIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteListIsLoading);
export const selectSiteListError = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteListError);

export const selectSelectedSiteId = createSelector(selectDashboardFeature, (state: DashboardState) => state.selectedSiteId);

export const selectSiteStructure = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteStructure);
export const selectSiteStructureIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteStructureIsLoading);
export const selectSiteStructureError = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteStructureError);

export const selectSiteOverview = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteOverview);
export const selectSiteMetering = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteMetering);
export const selectSiteDataIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteDataIsLoading);
export const selectSiteDataError = createSelector(selectDashboardFeature, (state: DashboardState) => state.siteDataError);

export const selectRefreshSiteDataIsLoading = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.refreshSiteDataIsLoading
);
export const selectRefreshSiteDataError = createSelector(selectDashboardFeature, (state: DashboardState) => state.refreshSiteDataError);

export const selectSelectedAssetId = createSelector(selectDashboardFeature, (state: DashboardState) => state.selectedAssetId);

export const selectAssetHistory = createSelector(selectDashboardFeature, (state: DashboardState) => state.assetHistory);
export const selectAssetHistoryIsLoading = createSelector(selectDashboardFeature, (state: DashboardState) => state.assetHistoryIsLoading);
export const selectAssetHistoryError = createSelector(selectDashboardFeature, (state: DashboardState) => state.assetHistoryError);

export const selectRefreshAssetHistoryIsLoading = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.refreshAssetHistoryIsLoading
);
export const selectRefreshAssetHistoryError = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.refreshAssetHistoryError
);

export const selectDashboardSiteSelectorVm = createSelector(
  selectSiteList,
  selectSiteListIsLoading,
  selectSiteListError,
  selectSelectedSiteId,
  (siteList, siteListIsLoading, siteListError, selectedSiteId): DashboardSiteSelectorVm => ({
    sites: siteList,
    sitesIsLoading: !!siteListIsLoading,
    sitesError: siteListError,
    selectedSiteId: selectedSiteId ?? null,
  })
);

export const selectDashboardSiteDetailsVm = createSelector(
  selectSiteStructureIsLoading,
  selectSiteStructureError,
  selectSiteOverview,
  selectSiteMetering,
  selectSiteDataIsLoading,
  selectSiteDataError,
  (siteStructureIsLoading, siteStructureError, siteOverview, siteMetering, siteDataIsLoading, siteDataError): DashboardSiteDetailsVm => ({
    siteDataIsLoading: !!siteStructureIsLoading || (!!siteDataIsLoading && (!siteOverview || !siteMetering)),
    siteDataError: siteStructureError || siteDataError,
    siteOverview,
    siteMetering,
  })
);

export const selectSiteOverviewTableViewModel = createSelector(
  selectSiteOverview,
  selectRefreshSiteDataError,
  selectRefreshSiteDataIsLoading,
  (siteOverview, refreshSiteDataError, refreshSiteDataIsLoading): SiteOverviewTableViewModel | undefined =>
    siteOverview && {
      tableData: siteOverview,
      isLoading: !!refreshSiteDataIsLoading,
      error: refreshSiteDataError,
    }
);

export const selectSiteGraphHistoryGraphViewModel = createSelector(
  selectSiteMetering,
  selectRefreshSiteDataError,
  selectRefreshSiteDataIsLoading,
  (siteMetering, refreshSiteDataError, refreshSiteDataIsLoading): SiteGraphHistoryGraphViewModel | undefined =>
    siteMetering && {
      graphData: siteMetering.meteredData,
      isLoading: !!refreshSiteDataIsLoading,
      error: refreshSiteDataError,
    }
);

export const selectDashboardAssetSelectorVm = createSelector(
  selectSiteStructure,
  selectSelectedAssetId,
  (siteStructure, selectedAssetId): DashboardAssetSelectorVm => {
    const assets = siteStructure?.assets;

    return { assets, selectedAssetId: selectedAssetId ?? null };
  }
);

export const selectDashboardAssetDetailsVm = createSelector(
  selectAssetHistory,
  selectAssetHistoryIsLoading,
  selectAssetHistoryError,
  (assetHistory, assetDataIsLoading, assetDataError): DashboardAssetDetailsVm => ({
    assetHistory: assetHistory?.meteredData,
    assetDataIsLoading: !!assetDataIsLoading,
    assetDataError,
  })
);

export const selectAssetGraphHistoryGraphViewModel = createSelector(
  selectAssetHistory,
  selectRefreshAssetHistoryIsLoading,
  selectRefreshAssetHistoryError,
  (assetHistory, refreshAssetHistoryIsLoading, refreshAssetHistoryError): AssetGraphHistoryGraphViewModel | undefined =>
    assetHistory && {
      graphData: assetHistory.meteredData,
      isLoading: !!refreshAssetHistoryIsLoading,
      error: refreshAssetHistoryError,
    }
);
