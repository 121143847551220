@if (vm(); as vm) {
  <h5 class="flex items-center gap-4">
    {{ 'dashboard.site-overview-table.title' | translate }}
    @if (vm.isLoading) {
      <app-loading-spinner size="small"></app-loading-spinner>
    }
  </h5>
  <table mat-table [dataSource]="vm.tableData" class="mat-elevation-z8">
    <ng-container matColumnDef="asset">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.site-overview-table.asset' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.asset }}</td>
    </ng-container>

    <ng-container matColumnDef="assetType">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.site-overview-table.asset-type' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.assetType | translateAssetType }}</td>
    </ng-container>

    <ng-container matColumnDef="activePowerKw">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.site-overview-table.active-power' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.activePowerKw | number }} {{ 'common.kw' | translate }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="!h-10"></tr>
  </table>
}
