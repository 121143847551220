import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';
import { DateTime } from 'luxon';
import { Observable, map, zip } from 'rxjs';

import { AssetTypeStoreModelEnum } from '@domain/dashboard/dashboard.enums';

import { AssetTypeKeyMapping } from '@shared/pipes/translate-enum-pipes/translate-asset-type.pipe';

import { LoadingSpinnerComponent } from '../loading-spinner';
import { SiteGraphHistoryGraphDataSet, SiteGraphHistoryGraphViewModel } from './site-graph-history-graph.view-model';

@Component({
  selector: 'app-site-graph-history-graph',
  standalone: true,
  imports: [LoadingSpinnerComponent, TranslateModule],
  templateUrl: './site-graph-history-graph.component.html',
  styleUrl: './site-graph-history-graph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteGraphHistoryGraphComponent implements OnChanges, AfterViewInit, OnDestroy {
  readonly #translateService = inject(TranslateService);
  readonly #destroyRef = inject(DestroyRef);

  public vm = input.required<SiteGraphHistoryGraphViewModel>();
  public chartContainer = viewChild<ElementRef<HTMLCanvasElement>>('chartContainer');

  private chart?: Chart<'line', { x: string; y: number }[], unknown>;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['vm'] && this.chart) {
      this.updateDataOnChart();
    }
  }

  public ngAfterViewInit(): void {
    this.initChart();
  }

  public ngOnDestroy(): void {
    this.chart?.destroy();
  }

  private initChart(): void {
    const chartContainer = this.chartContainer();
    if (!chartContainer) {
      return;
    }

    const ctx = chartContainer.nativeElement.getContext('2d')!;

    this.getDatasets$()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((datasets) => {
        this.chart = new Chart(ctx, {
          type: 'line',
          data: {
            datasets,
          },
        });
      });
  }

  private updateDataOnChart(): void {
    this.getDatasets$()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((datasets) => {
        this.chart?.data.datasets.forEach((dataset, index) => {
          dataset.data = datasets[index].data;
        });

        this.chart?.update();
      });
  }

  private getDatasets$(): Observable<SiteGraphHistoryGraphDataSet[]> {
    return zip([
      this.#translateService.stream(AssetTypeKeyMapping[AssetTypeStoreModelEnum.Grid]),
      this.#translateService.stream(AssetTypeKeyMapping[AssetTypeStoreModelEnum.Bess]),
      this.#translateService.stream(AssetTypeKeyMapping[AssetTypeStoreModelEnum.DistributionComponent]),
      this.#translateService.stream(AssetTypeKeyMapping[AssetTypeStoreModelEnum.Evse]),
      this.#translateService.stream(AssetTypeKeyMapping[AssetTypeStoreModelEnum.ChargingStation]),
    ]).pipe(
      map(([gridLabel, bessLabel, distributionComponentLabel, evseLabel, chargingStationLabel]) => {
        const vm = this.vm();

        const dataSets: SiteGraphHistoryGraphDataSet[] = [];

        if (vm.graphData[AssetTypeStoreModelEnum.Grid]) {
          dataSets.push({
            label: gridLabel,
            data: vm.graphData[AssetTypeStoreModelEnum.Grid].map((graphData) => {
              return {
                x: DateTime.fromISO(graphData.timestamp).toFormat('hh:mm:ss a').toUpperCase(),
                y: graphData.activePowerKw,
              };
            }),
          });
        }

        if (vm.graphData[AssetTypeStoreModelEnum.Bess]) {
          dataSets.push({
            label: bessLabel,
            data: vm.graphData[AssetTypeStoreModelEnum.Bess].map((graphData) => {
              return {
                x: DateTime.fromISO(graphData.timestamp).toFormat('hh:mm:ss a').toUpperCase(),
                y: graphData.activePowerKw,
              };
            }),
          });
        }

        if (vm.graphData[AssetTypeStoreModelEnum.DistributionComponent]) {
          dataSets.push({
            label: distributionComponentLabel,
            data: vm.graphData[AssetTypeStoreModelEnum.DistributionComponent].map((graphData) => {
              return {
                x: DateTime.fromISO(graphData.timestamp).toFormat('hh:mm:ss a').toUpperCase(),
                y: graphData.activePowerKw,
              };
            }),
          });
        }

        if (vm.graphData[AssetTypeStoreModelEnum.Evse]) {
          dataSets.push({
            label: evseLabel,
            data: vm.graphData[AssetTypeStoreModelEnum.Evse].map((graphData) => {
              return {
                x: DateTime.fromISO(graphData.timestamp).toFormat('hh:mm:ss a').toUpperCase(),
                y: graphData.activePowerKw,
              };
            }),
          });
        }

        if (vm.graphData[AssetTypeStoreModelEnum.ChargingStation]) {
          dataSets.push({
            label: chargingStationLabel,
            data: vm.graphData[AssetTypeStoreModelEnum.ChargingStation].map((graphData) => {
              return {
                x: DateTime.fromISO(graphData.timestamp).toFormat('hh:mm:ss a').toUpperCase(),
                y: graphData.activePowerKw,
              };
            }),
          });
        }

        return dataSets;
      })
    );
  }
}
